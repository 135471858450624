import React, { useEffect, useState } from "react";

const Share = ({ title }) => {
  const [hover, setHover] = useState();
  const [url, setUrl] = useState("https://standupwizard.com/blog");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setUrl(window.location.href);
    }
  });

  return (
    <div className="hidden md:block fixed left-0 top-1/4 z-50">
      <div
        style={{ width: 45 }}
        className="py-3 font-bold text-xs text-center text-gray-800"
      >
        Share
      </div>
      <div>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url,
          )}`}
          className={`flex ${hover === "1" ? "rounded-r-md" : "rounded-tr-md"}`}
          style={{
            width: hover === "1" ? 150 : 50,
            height: 50,
            background: "#4267b2",
          }}
          onMouseEnter={() => setHover("1")}
          onMouseLeave={() => setHover(null)}
        >
          <span
            className="flex items-center justify-center"
            style={{ width: 50, height: 50 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="text-white"
            >
              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
          </span>
          {hover === "1" && (
            <span
              style={{ width: 90, height: 50 }}
              className="flex items-center pl-3 text-white font-bold"
            >
              Facebook
            </span>
          )}
        </a>
        <a
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
            `${title} ${url}`,
          )}`}
          className={`flex ${hover === "2" ? "rounded-r-md" : ""}`}
          style={{
            width: hover === "2" ? 150 : 50,
            height: 50,
            background: "#1e9af0",
          }}
          onMouseEnter={() => setHover("2")}
          onMouseLeave={() => setHover(null)}
        >
          <span
            className="flex items-center justify-center"
            style={{ width: 50, height: 50 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="text-white"
            >
              <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
            </svg>
          </span>
          {hover === "2" && (
            <span
              style={{ width: 100, height: 50 }}
              className="flex items-center pl-3 text-white font-bold"
            >
              Twitter
            </span>
          )}
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            url,
          )}&title=${encodeURIComponent(title)}`}
          className={`flex ${hover === "3" ? "rounded-r-md" : ""}`}
          style={{
            width: hover === "3" ? 150 : 50,
            height: 50,
            background: "#0866c2",
          }}
          onMouseEnter={() => setHover("3")}
          onMouseLeave={() => setHover(null)}
        >
          <span
            className="flex items-center justify-center"
            style={{ width: 50, height: 50 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="text-white"
            >
              <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
              <rect x="2" y="9" width="4" height="12"></rect>
              <circle cx="4" cy="4" r="2"></circle>
            </svg>
          </span>
          {hover === "3" && (
            <span
              style={{ width: 100, height: 50 }}
              className="flex items-center pl-3 text-white font-bold"
            >
              LinkedIn
            </span>
          )}
        </a>
        <a
          href={`mailto:?body=${encodeURIComponent(`${title} ${url}`)}`}
          className={`flex bg-emerald-600 ${
            hover === "4" ? "rounded-r-md" : "rounded-br-md"
          }`}
          style={{
            width: hover === "4" ? 150 : 50,
            height: 50,
          }}
          onMouseEnter={() => setHover("4")}
          onMouseLeave={() => setHover(null)}
        >
          <span
            className="flex items-center justify-center"
            style={{ width: 50, height: 50 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-white"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
          </span>
          {hover === "4" && (
            <span
              style={{ width: 100, height: 50 }}
              className="flex items-center pl-3 text-white font-bold"
            >
              Email
            </span>
          )}
        </a>
      </div>
    </div>
  );
};

export default Share;
